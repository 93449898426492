<template>
  <div class="wx-app-tb-rel-config">
    <p class="config-item-title">淘宝关联设置</p>

    <el-form
      class="config-form"
      inline
      label-suffix=":"
      size="mini" 
      v-loading="loading"
    >
      <el-form-item label="当前关联">
        <el-autocomplete
          v-model="showTbAuth"
          :fetch-suggestions="fetchSuggestions"
          placeholder="请选择关联的淘宝账号"
          value-key="caption"
          size="mini"
          clearable
          suffix-icon="el-icon-search"
          @select="selectTaoBaoAuth"
          @clear="clearTaoBaoAuth"
        >
        </el-autocomplete>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="seting"
          type="primary"
          icon="el-icon-check"
          @click="setRel"
          >保存关联</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "wx-app-tb-rel-config",
  props: {
    appConfigId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      seting: false,
      showTbAuth: "",
      selectedTaoBaoAuthId: 0
    };
  },
  methods: {
    async setRel() {
      let taoBaoAuthId = this.selectedTaoBaoAuthId;
      if (!taoBaoAuthId) {
        taoBaoAuthId = 0;
      }
      try {
        this.seting = true;
        await this.$http.doApi("fw-wx-app-setTbRel", {
          wxAppId: this.appConfigId,
          tbAuthId: taoBaoAuthId,
        });
        this.$message.success(taoBaoAuthId > 0 ? '设置成功' : '清除成功');
      } catch (e) {
        console.log(e);
      } finally {
        this.seting = false;
      }
    },
    clearTaoBaoAuth() {
      this.selectedTaoBaoAuthId = 0;
    },
    selectTaoBaoAuth(item) {
      this.selectedTaoBaoAuthId = item.code;
    },
    fetchSuggestions(text, callback) {
      this.$http
        .doApi("qd-service-codes", {
          codeDomain: "ztkTaobaoAuth-codes",
          searchToken: text,
          allowBlank: true
        })
        .then((data) => {
          callback(data.rows);
        })
        .catch((e) => {
          console.log(e);
          callback([]);
        });
    },
    async loadRel(appConfigId) {
      try {
        this.loading = true;
        let data = await this.$http.doApi("fw-wx-app-getRel", {
          wxAppId: appConfigId,
        });
        if (data.tbAuthId && data.tbAuthId > 0) {
          this.selectedTaoBaoAuthId = data.tbAuthId;
          this.showTbAuth = data.tbNick;
        } else {
          this.showTbAuth = "";
          this.selectedTaoBaoAuthId = 0;
        }
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    appConfigId: {
      immediate: true,
      handler(val) {
        this.loadRel(val);
      },
    },
  },
};
</script>

<style lang="less">
.wx-app-tb-rel-config {
  .config-item-title {
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
  }

  .config-form {
    border: 1.5px #cccccc dotted;
    border-radius: 5px;
    padding: 1rem 1rem;

    .el-form-item {
      margin-bottom: 0px;
    }
  }
}
</style>